import { getEmbedIframeDomain } from './env';
import { getPortalId } from './cms';
import { debugLog } from './debug';
const HS_SCRIPT_LOADER_ID = 'hs-script-loader';
const HS_SCRIPT_LOADER_PATH_PREFIX = '/hs/scriptloader/';
export const VIDEO_EMBED_SRC_PATTERN = /video-embed\/ex\/loader(-qa)?.js/;
export const GENERIC_PLACEHOLDER_SELECTOR = 'div.hs-video-widget[data-hsv-embed-id]';
export const UNUSED_PLACEHOLDER_SELECTOR = `${GENERIC_PLACEHOLDER_SELECTOR}:not([data-hsv-status])`;
export const SERVER_RENDERED_IFRAME_SELECTOR = `iframe[data-hsv-src]`;

// we still see legacy browsers which lack NodeList.forEach, Array.from etc
export function query(selector) {
  return [].slice.call(document.querySelectorAll(selector));
}
export function queryEl(el, selector) {
  return [].slice.call(el.querySelectorAll(selector));
}
export function getSelfScriptEl() {
  const scripts = query('script[src]');
  return scripts.find(scriptEl => VIDEO_EMBED_SRC_PATTERN.test(scriptEl.src) || scriptEl.src.endsWith('/video-embed/static/bundles/loader.js'));
}
export function parsePortalIdFromScriptLoader() {
  const scriptLoaderEl = document.getElementById(HS_SCRIPT_LOADER_ID);
  if (scriptLoaderEl && scriptLoaderEl.src && scriptLoaderEl.src.includes(HS_SCRIPT_LOADER_PATH_PREFIX)) {
    const a = document.createElement('a');
    a.href = scriptLoaderEl.src;
    const portalIdStr = a.pathname.replace(HS_SCRIPT_LOADER_PATH_PREFIX, '').replace('.js', '');
    const portalId = Number(portalIdStr);
    if (portalId > 0) {
      return portalId;
    }
  }
  return null;
}
export function getSelfScriptSrc() {
  const scriptEl = getSelfScriptEl();
  return scriptEl ? scriptEl.src : null;
}
export function getEmbedPlaceholderEl(id, onlyUnused = false) {
  if (onlyUnused) {
    return document.querySelector(`[data-hsv-embed-id="${id}"]:not([data-hsv-status])`);
  }
  return document.querySelector(`[data-hsv-embed-id="${id}"]`);
}
export function hasDocLoaded() {
  return document.readyState === 'interactive' || document.readyState === 'complete';
}
export const SCRIPT_LOAD_TIMEOUT_MS = 10000;
export function loadScript(src) {
  const script = document.createElement('script');
  return new Promise((resolve, reject) => {
    let loaded = false;
    script.onload = () => {
      loaded = true;
      resolve();
    };
    script.onerror = () => reject(new Error(`loadScript failed for: ${src}`));
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
    setTimeout(() => {
      if (!loaded) {
        reject(new Error(`loadScript timed out for: ${src}`));
      }
    }, SCRIPT_LOAD_TIMEOUT_MS);
  });
}
export function setStyles(el, styleObj = {}) {
  if (el instanceof HTMLElement) {
    Object.assign(el.style, styleObj);
  }
}
export function hasParentWithClass(el, className) {
  if (!className.startsWith('.')) {
    className = `.${className}`;
  }
  while (el && el.parentElement) {
    // SVGElement className is not a simple string
    if (el.parentElement.matches && el.parentElement.matches(className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
}

// is any of element within the viewport
export function isVisible(el) {
  const rect = el.getBoundingClientRect();
  return rect.bottom > 0 && rect.right > 0 && rect.left < window.innerWidth && rect.top < window.innerHeight;
}
export function isTransparent(el) {
  const {
    backgroundColor
  } = getComputedStyle(el);
  return backgroundColor === 'transparent' || backgroundColor === 'rgba(0, 0, 0, 0)';
}
export function getColorValue(hexColor) {
  // borrowed from https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  const rgb = parseInt(hexColor.substring(1), 16);
  // eslint-disable-next-line no-bitwise
  const r = rgb >> 16 & 0xff; // extract red
  // eslint-disable-next-line no-bitwise
  const g = rgb >> 8 & 0xff; // extract green
  // eslint-disable-next-line no-bitwise
  const b = rgb >> 0 & 0xff; // extract blue
  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}
export function insertPreloaderIframe(onIframeLoaded) {
  const iframeEl = document.createElement('iframe');
  iframeEl.src = `${getEmbedIframeDomain()}/video-preloader/${getPortalId()}`;
  setStyles(iframeEl, {
    display: 'none',
    width: 0,
    height: 0,
    border: '0 none'
  });
  debugLog('Created preloader iframe', iframeEl);
  iframeEl.addEventListener('load', () => {
    debugLog('Preloader iframe loaded, removing from page.');
    iframeEl.remove();
    onIframeLoaded();
  });
  document.body.appendChild(iframeEl);
}